import { Outlet, useParams } from "react-router-dom"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Stack, useTheme } from "@mui/material"

import usePowerline from "helpers/hooks/powerlines/usePowerline"
import Title from "widgets/common/Title"
import LinkTabsSection from "widgets/common/LinkTabsSection"
import { getPowerlineTabsConfig } from "helpers/config/tabsConfigs"
import Spinner from "widgets/common/Spinner"
import PreviousPage from "widgets/common/PreviousPage"
import useRoutes from "helpers/hooks/useRoutes"
import { ItemTag } from "widgets/styled/widgets"
import DashboardDatePicker from "widgets/device/Dashboard/DashboardDatePicker"
import useDateRange from "helpers/hooks/useDateRange"
import ScrollToTopButton from "widgets/common/ScrollToTopButton"

const Powerline = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { id } = useParams()
  const { powerline, isFetching: powerlineFetching } = usePowerline({ id: Number(id) })
  const { baseOrgURL } = useRoutes()
  const { powerlineDateRange, dispatchPowerlineDateRange, powerlineSearchParams } =
    useDateRange()

  const powerlinesTabConfig = useMemo(
    () => getPowerlineTabsConfig(powerlineSearchParams),
    [powerlineSearchParams],
  )

  return (
    <>
      {powerlineFetching && !powerline ? (
        <Spinner styles={{ my: 2 }} />
      ) : (
        <>
          <PreviousPage href={`${baseOrgURL}/powerlines`} />
          <Stack
            flexDirection="row"
            alignItems="start"
            justifyContent="space-between"
            sx={{ [theme.breakpoints.down("md")]: { flexDirection: "column" } }}
          >
            <Stack
              flexDirection={"row"}
              gap={"15px"}
              alignItems={"center"}
              marginBottom={"0.5rem"}
            >
              <ItemTag>{t("powerlines.POWERLINE")}</ItemTag>
              <Title text={powerline?.name || ""} styles={{ p: 0 }} />
            </Stack>
            <DashboardDatePicker
              dateRange={powerlineDateRange}
              dispatchDateRange={dispatchPowerlineDateRange}
            />
          </Stack>
          <LinkTabsSection tabsConfig={powerlinesTabConfig} />
          <Outlet context={{ powerline, powerlineFetching }} />
          <ScrollToTopButton />
        </>
      )}
    </>
  )
}

export default Powerline
