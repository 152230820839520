import fp from "lodash/fp"

import type { EntityTelemetry } from "types/telemetries.types"
import type { IPadding, IViewBox } from "types/linePlot.types"

export const limitsOfReferenceArea = (section: any, state: any) => {
  /**
   * TODO review state.top and state.bottom, they are inverted :(
   */

  let top: number | string | undefined = section.top
  let bottom: number | string | undefined = section.bottom
  if (typeof state.top === "number" && typeof state.bottom === "number") {
    // case top and bottom number
    if (typeof section.top === "number" && typeof section.bottom === "number") {
      top = section.top > state.bottom ? state.bottom : section.top
      bottom = section.bottom < state.top ? state.top : section.bottom

      return [top, bottom]
    }
    // case top with number and bottom undefined
    if (!bottom && typeof section.top === "number") {
      top =
        section.top < state.top
          ? state.top
          : section.top < state.bottom && section.top > state.top
          ? section.top
          : state.bottom

      bottom = state.top

      return [top, bottom]
    }

    // case top undefined and bottom with number
    if (!top && typeof section.bottom === "number") {
      bottom =
        section.bottom > state.bottom
          ? state.bottom
          : section.bottom < state.bottom && section.bottom > state.top
          ? section.bottom
          : state.top

      top = state.bottom

      return [top, bottom]
    }
  }

  return [top, bottom]
}

type FormatTelemetryPlotData = (data?: EntityTelemetry[]) => EntityTelemetry[]
export const formatTelemetryPlotData: FormatTelemetryPlotData = (data) =>
  (data || []).map((d) => ({
    ...d,
    ...(fp.isBoolean(d._value) && { _value: d._value ? 1 : 0 }),
  }))

/**
 * translate the coordinates from pixel to values in the Y domain scale
 *
 * @param y coordinates in pixels
 * @param domainY
 * @param viewBox
 * @param {Record<string,number> | undefined} axisPadding
 *
 * @returns coordinate in the current scale
 */
export const yCoordinateFromPixels = (
  y: number,
  domainY: number[],
  viewBox: IViewBox,
  axisPadding?: IPadding,
): number => {
  const top = viewBox?.top ?? 0
  const bottom = viewBox?.bottom ?? top + (viewBox?.height ?? 0)
  const pixelDomain = pad(
    [bottom, top],
    [axisPadding?.bottom ?? 0, axisPadding?.top ?? 0],
  )
  const pixelDomainHeight = pixelDomain[0] - pixelDomain[1]
  if (pixelDomainHeight <= 0) {
    throw new Error("Effective height can't be zero or negative")
  }
  const domain: Domain = [domainY[0], domainY[1]]
  return linearConversion(pixelDomain, domain, y)
}

/**
 * translate the coordinate from pixels to value in the X domain scale
 *
 * @param x coordinate in pixels
 * @param {number[]} domainX
 * @param viewBox
 *
 * @returns { number} coordinate in the current scale
 */
export const xCoordinateFromPixels = (
  x: number,
  domainX: number[],
  viewBox: IViewBox,
): number => {
  const left = viewBox?.left ?? 0
  const right = viewBox?.right ?? left + (viewBox?.width ?? 0)
  const pixelDomain: Domain = [left, right]

  const pixelDomainWidth = pixelDomain[1] - pixelDomain[0]
  if (pixelDomainWidth <= 0) {
    throw new Error("Effective width can't be zero or negative")
  }

  const domain: Domain = [domainX[0], domainX[1]]

  return linearConversion(pixelDomain, domain, x)
}

export type Domain = [number, number]
export type Pad = [number, number]

/** Shrink the domain
 *
 * Reduce the domain by the padding amount, the values in the pad need to be in
 * the same units as the domain.
 */
export const pad = (domain: Domain, pad: Pad): Domain => {
  if (domain[1] > domain[0]) {
    return [domain[0] + pad[0], domain[1] - pad[1]]
  }
  return [domain[0] - pad[0], domain[1] + pad[1]]
}

/** Expand the domain
 *
 * This acts as the inverse of pad, it extends the domain bounds the padding
 * amount. So the resulting domain covers the whole segment.
 */
export const unpad = (domain: Domain, pad: Pad): Domain => {
  if (domain[1] > domain[0]) {
    return [domain[0] - pad[0], domain[1] + pad[1]]
  }
  return [domain[0] + pad[0], domain[1] - pad[1]]
}

// Switch value between domains
export const linearConversion = fp.curry(
  (from: Domain, to: Domain, x: number): number => {
    const fromRange = from[1] - from[0]
    const toRange = to[1] - to[0]
    const scaled = rescale(fromRange, toRange, x - from[0])
    return scaled + to[0]
  },
)

// Scale value
export const rescale = fp.curry(
  (fromRange: number, toRange: number, x: number): number => {
    const ratio = toRange / fromRange
    return x * ratio
  },
)

// Move the domain to the 0 coord
export const toOrigin = (domain: Domain): Domain => {
  return [0, domain[1] - domain[0]]
}

export const isCoordInDomain = (coord: number, domain: Domain): boolean => {
  if (domain[1] > domain[0]) {
    return domain[0] <= coord && coord <= domain[1]
  }
  return domain[1] <= coord && coord <= domain[0]
}

export type XYCoordinates = [number, number]
export type Domain2D = [Domain, Domain]

export const areCoordsInXYDomain = (coords: XYCoordinates, domain: Domain2D): boolean =>
  isCoordInDomain(coords[0], domain[0]) && isCoordInDomain(coords[1], domain[1])

/**
 * Check if coordinates are within domain range
 */
export const areCoordsOnDomain = (
  xCoord: number,
  yCoord: number,
  domainX: number[],
  domainY: number[],
  axisPadding?: IPadding,
  viewBox?: IViewBox,
): boolean => {
  const dDomainY = domainY[1] - domainY[0]

  // from PX to domain scale
  const offsetTop = viewBox?.height
    ? ((axisPadding?.top ?? 0) / viewBox?.height) * dDomainY
    : 0
  const offsetBottom = viewBox?.height
    ? ((axisPadding?.bottom ?? 0) / viewBox?.height) * dDomainY
    : 0

  const isXInDomain = domainX.length > 0 && domainX[0] <= xCoord && xCoord <= domainX[1]
  const isYInDomain =
    domainY.length > 0 &&
    domainY[0] - offsetBottom <= yCoord &&
    yCoord <= domainY[1] + offsetTop

  const isInBothDomains = isXInDomain && isYInDomain

  return isInBothDomains
}

export interface ILegendRecord {
  legend?: unknown
  [key: string]: unknown
}

/**
 * Lookup for a legend in the data
 *
 * @param {ILegendRecord[]} data
 * @returns {string | undefined} the legend found or undefined if none was present
 */
export const getLegend = (data?: ILegendRecord[]): string | undefined => {
  const { legend } = (data?.find((record) => typeof record.legend === "string") || {
    legend: undefined,
  }) as {
    legend: string | undefined
  }
  return legend
}
