import type { FC } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { FormProvider, useForm } from "react-hook-form"
import { Stack } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import CloseIcon from "@mui/icons-material/Close"
import fp from "lodash/fp"

import type { SelectOptionType } from "types/common.types"
import type { DeviceGroup } from "types/group.types"
import type { SellDeviceRes } from "types/device.types"

import {
  useActivateDeviceMutation,
  useCreateDeviceMutation,
  useDeleteDeviceMutation,
  useDeviceGroupsQuery,
  useSellDeviceMutation,
} from "features/api"
import { selectCurrentOrgId } from "features/store/orgSlice"
import { DEVICE_TYPES, NEW_DEVICE_BACKEND } from "helpers/utils/constants"
import { snackbarMutation } from "helpers/utils/mutations"
import { addEndPeriodIfMissing } from "helpers/utils/translations"
import ControlledInput from "widgets/common/ControlledInput"
import CardWithTitle from "widgets/common/CardWithTitle"
import FormButtons from "widgets/common/FormButtons"

interface ICreateDevicePopupProps {
  open: boolean
  onClose: () => void
}

interface ICreateDeviceForm {
  lessId: string
  deviceName: string
  deviceType: string
  groupId: string
  orgId: number
}

const CreateDevicePopup: FC<ICreateDevicePopupProps> = ({ onClose, open }) => {
  const { t } = useTranslation()
  const orgId = useSelector(selectCurrentOrgId)
  const deviceTypesOptions: SelectOptionType[] = fp.flow(
    fp.map((deviceType: string) => ({
      label: t(`device_types.${deviceType.toUpperCase()}`),
      value: deviceType,
    })),
  )(DEVICE_TYPES)
  const { currentData: groups } = useDeviceGroupsQuery({
    params: { describe_devices: false, org_id: orgId as number },
  })
  const deviceGroupsOptions: SelectOptionType[] = fp.flow(
    fp.map((group: DeviceGroup) => ({
      label: group.name,
      value: String(group.id),
    })),
    fp.sortBy(({ label }) => label.toLowerCase()),
  )(groups)

  const methods = useForm<ICreateDeviceForm>({
    mode: "all",
    defaultValues: {
      lessId: "",
      deviceName: "",
      deviceType: "",
      groupId: "",
      orgId: orgId as number,
    },
  })
  const [createDevice, { isLoading: createDeviceIsLoading }] = useCreateDeviceMutation()
  const [sellDevice, { isLoading: sellDeviceIsLoading }] = useSellDeviceMutation()
  const [activateDevice, { isLoading: activateDeviceIsLoading }] =
    useActivateDeviceMutation()
  const [deleteDevice, { isLoading: deleteDeviceIsLoading }] = useDeleteDeviceMutation()
  const isLoading =
    createDeviceIsLoading ||
    sellDeviceIsLoading ||
    activateDeviceIsLoading ||
    deleteDeviceIsLoading

  const createSellAndActivateDevice = async (data: ICreateDeviceForm): Promise<null> => {
    const create = (): Promise<null> =>
      createDevice({
        orgId: orgId as number,
        lessId: Number(data.lessId),
        deviceName: data.deviceName,
        deviceType: data.deviceType,
        settings: {
          backend: NEW_DEVICE_BACKEND,
        },
      }).unwrap()

    const sell = (): Promise<SellDeviceRes> =>
      sellDevice({
        lessId: Number(data.lessId),
      }).unwrap()

    const activate = (sellDeviceData: SellDeviceRes): Promise<null> =>
      activateDevice({
        orgId: orgId as number,
        lessId: Number(data.lessId),
        qrId: sellDeviceData.qr_id,
        groupId: Number(data.groupId),
        deviceName: data.deviceName,
      }).unwrap()

    const _delete = (): Promise<null> =>
      deleteDevice({ lessId: Number(data.lessId) }).unwrap()

    return create().then(() =>
      sell()
        .then(activate)
        .catch((error) => _delete().then(() => Promise.reject(error))),
    )
  }

  const onSubmit = async (data: ICreateDeviceForm) => {
    snackbarMutation({
      mutation: createSellAndActivateDevice(data),
      getSuccessMessage: () => t("device.CREATED_SUCCESSFULLY"),
      getErrorMessage: (error) => {
        const errorText = t("error.CREATING_ITEM", {
          item: t("generic.DEVICE").toLowerCase(),
        })
        const message = fp.get("data.message", error) || t("error.NETWORK_ERROR")
        return `${errorText}: ${addEndPeriodIfMissing(message)}`
      },
    })
      .then(onClose)
      .catch()
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
      <CardWithTitle
        titleKey="device.CREATE_DEVICE"
        handleAction={onClose}
        actionIcon={<CloseIcon fontSize="small" color="action" />}
      >
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormProvider {...methods}>
            <Stack mb={2}>
              <ControlledInput
                name="lessId"
                placeholder={t("device.DEVICE_ID")}
                label={t("device.DEVICE_ID")}
                rules={{ required: true }}
                variant="outlined"
                disabled={isLoading}
                autoFocus
              />
              <ControlledInput
                name="deviceName"
                placeholder={t("enterprise.DEVICE_NAME")}
                label={t("enterprise.DEVICE_NAME")}
                rules={{ required: true }}
                variant="outlined"
                disabled={isLoading}
              />
              <ControlledInput
                name="deviceType"
                type="select"
                selectItems={deviceTypesOptions}
                placeholder={t("enterprise.DEVICE_TYPE")}
                label={t("enterprise.DEVICE_TYPE")}
                rules={{ required: true }}
                variant="outlined"
                disabled={isLoading}
              />
              <ControlledInput
                name="groupId"
                type="select"
                selectItems={deviceGroupsOptions}
                placeholder={t("device_groups.GROUP_NAME")}
                label={t("device_groups.GROUP_NAME")}
                rules={{ required: true }}
                variant="outlined"
                disabled={isLoading}
              />
            </Stack>
            <FormButtons
              buttonText="generic.CREATE"
              onClose={onClose}
              disabled={isLoading}
              isLoading={isLoading}
            />
          </FormProvider>
        </form>
      </CardWithTitle>
    </Dialog>
  )
}

export default CreateDevicePopup
