import { Stack, useMediaQuery, useTheme } from "@mui/material"
import { useMemo } from "react"
import type { FC } from "react"
import { Cell, Customized, Pie, PieChart, PolarAngleAxis } from "recharts"

import { useTranslation } from "react-i18next"
import { gaugeDataChartFormatter } from "helpers/formatters/plots/gauge"
import Spinner from "widgets/common/Spinner"
import type { CellViewRes, RecordType } from "types/dashboard.types"
import type { ITicksProps } from "types/gaugePlot.types"

import useGaugeConfig from "../../configuration/useGaugeConfig"
import RadialGradientComponent from "../components/RadialGradient"
import FinalDot from "./FinalDot"
import GaugeLabel from "./GaugeLabel"
import GaugeTicks from "./GaugeTick"

interface GaugeChartProps {
  view: CellViewRes
  data: RecordType[]
}

const GaugeChart: FC<GaugeChartProps> = ({ view, data }) => {
  const { i18n } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const config = useGaugeConfig(view, data)
  const { cellBG, decimalPlaces, domain, fill, plotSize, range, ticks, values } = config
  const locale = i18n.resolvedLanguage === "en" ? "en-gb" : i18n.language

  // resize gauge when viewport width is 'sm'
  const size = useMemo(
    () => (isMobile ? plotSize.lengthMobile : plotSize.length),
    [isMobile, plotSize],
  )
  const offset = 7 // this offset is the offset of the extreme values of the 'cell' from the exact value
  const radius = {
    delta: size / 13,
    inner: size / 3.5,
    outer: size / 3.5 + size / 13,
  }
  const position = { cx: size / 2, cy: size / 2 }
  const startAngle = range.max + offset
  const endAngle = range.min - offset

  const { cellDataFormatted, valueOnRangeExact } = gaugeDataChartFormatter(
    values.value ?? 0,
    domain,
    offset,
    range,
    fill.name ?? "",
    fill.gradient !== undefined,
  )

  if (!cellDataFormatted) {
    return (
      <Stack justifyContent="center" alignItems="center">
        <Spinner />
      </Stack>
    )
  }

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      aria-label={`${fill.name}-gauge`}
      sx={{
        "& .recharts-wrapper, & svg": {
          width: "100% !important",
        },
      }}
    >
      <PieChart
        width={size}
        height={size}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        {/* ---- gradient defs ---- */}
        {fill?.gradient && (
          <defs>
            <RadialGradientComponent config={fill.gradient} />
          </defs>
        )}
        {/* ---- polar ticks svg ---- */}
        <PolarAngleAxis
          type="number"
          domain={[domain.max, domain.min]} // These values are switched because the scale is drawn counterclockwise
          dataKey="value"
          range={[range.min, range.max]}
          tickCount={30}
          tick={(gaugeProps: ITicksProps) => {
            return <GaugeTicks gaugeProps={gaugeProps} ticks={ticks} />
          }}
          tickSize={5}
          axisLine={true}
          viewBox="polar"
        />
        {/* ---- background svg ---- */}
        <Pie
          isAnimationActive={false}
          dataKey="value"
          startAngle={startAngle}
          endAngle={endAngle}
          data={[{ name: "background", value: range.max }]}
          cx={position.cx}
          cy={position.cy}
          cornerRadius={radius.delta}
          innerRadius={radius.inner}
          outerRadius={radius.outer}
          stroke="none"
        >
          <Cell
            key={"cell-background"}
            fill={cellBG.fill}
            style={{
              outline: "none",
              filter: `drop-shadow(0px 0px 5px ${cellBG.filter}`,
            }}
          />
        </Pie>
        {/* ---- pie svg ---- */}
        <Pie
          isAnimationActive={false}
          dataKey="value"
          startAngle={startAngle}
          endAngle={range.min}
          data={cellDataFormatted}
          cx={position.cx}
          cy={position.cy}
          cornerRadius={radius.delta}
          innerRadius={radius.inner}
          outerRadius={radius.outer}
          stroke="none"
        >
          {cellDataFormatted?.map((entry: any, index: number) => (
            <Cell
              key={`cell-${index}`}
              fill={entry.color}
              style={{
                outline: "none",
              }}
            />
          ))}
          {/* ---- labels svg ---- */}
          {GaugeLabel({ values, size, decimalPlaces, locale })}
        </Pie>

        {/* ---- final dot svg ---- */}
        <Customized
          component={
            <>
              <FinalDot
                gaugeAngle={valueOnRangeExact}
                radius={radius}
                position={position}
                range={range}
              />
            </>
          }
        />
      </PieChart>
    </Stack>
  )
}

export default GaugeChart
