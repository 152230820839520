import fp from "lodash/fp"

import type { IPlotDef, PowerlinePlotDef } from "types/dashboard.types"

import { lineView, plotDefBase } from "./base"

export const positionPlotView = fp.merge(lineView, {
  name: "Position",
  nameKey: "POSITION",
  properties: {
    axes: {
      y: { bounds: [-180, 180] },
    },
    fill: ["id", "_field"],
    tooltip: ["plots_descriptions.POSITION", "plots_descriptions.AGGREGATE_NOTE"],
  },
})

const temperaturePlotView = fp.merge(lineView, {
  name: "Temperature [°C]",
  nameKey: "TEMPERATURE",
  properties: {
    decimalPlaces: {
      isEnforced: true,
      digits: 0,
    },
    axes: {
      y: {
        suffix: "°C",
      },
    },
    fill: ["id", "_field", "result"],
    generateXAxisTicks: null,
    generateYAxisTicks: null,
    hoverDimension: "xy",
    legendColorizeRows: true,
    legendColumns: ["name", "_value", "_time"],
    tooltip: ["plots_descriptions.AGGREGATE_NOTE"],
  },
})

export const temperaturePlotDef: IPlotDef = fp.merge(plotDefBase, {
  view: temperaturePlotView,
  reqConfig: {
    fields: [
      "sensors.thermal.raw.frame.cable_estimate",
      "sensors.thermal.temperatures.frame.cable_estimate",
    ],
    aggregate: ["mean"],
    group: ["id", "_field"],
  },
})

const motionPlotView = fp.merge(lineView, {
  name: "Motion detection",
  nameKey: "MOTION_DETECTION",
  properties: {
    axes: {
      y: {
        bounds: ["0", "1"],
        label: "Motion change and state",
        base: "",
      },
    },
    geom: "stepAfter",
    fill: ["id", "_field"],
    legendColumns: ["name", "_value", "_time"],
    generateXAxisTicks: null,
    generateYAxisTicks: ["yTickStep", "yTickStart"],
    yTickStep: 1,
    legendColorizeRows: true,
    legendOrientationThreshold: 100000000,
    tooltip: ["plots_descriptions.MOTION", "plots_descriptions.AGGREGATE_NOTE"],
  },
})

export const motionPlotDef: IPlotDef = fp.merge(plotDefBase, {
  view: motionPlotView,
  reqConfig: {
    fields: ["sensors.motion.alerts.motion"],
    aggregate: ["max"],
    group: ["id", "_field"],
    method: ["toInt"],
  },
})

const motionIntensityPlotView = fp.merge(lineView, {
  name: "Motion intensity",
  nameKey: "MOTION_INTENSITY",
  properties: {
    axes: {
      y: {
        bounds: ["0", "2"],
        label: "Motion change and state",
        base: "",
      },
    },
    geom: "linear",
    fill: ["id", "_field", "table"],
    legendColumns: ["name", "_value", "_time"],
    generateXAxisTicks: null,
    generateYAxisTicks: ["yTickStep", "yTickStart"],
    yTickStep: 0.5,
    legendColorizeRows: true,
    legendOrientationThreshold: 100000000,
    tooltip: ["plots_descriptions.MOTION_INTENSITY"],
  },
})

export const motionIntensityPlotDef: IPlotDef = {
  view: motionIntensityPlotView,
  draggable: true,
  reqConfig: {
    fields: ["derived.motion.history.jerk.norm"],
    aggregate: ["spread"],
    aggregate_every: "PT5S", // 5 seconds
    group: ["id", "_field", "alert"],
  },
}

const displacementAmplitudePlotView = fp.merge(lineView, {
  name: "Displacement amplitude",
  nameKey: "DISPLACEMENT_AMPLITUDE",
  properties: {
    axes: {
      y: {
        suffix: "m",
      },
    },
    fill: ["id", "_field", "result"],
    legendColumns: ["name", "_value", "_time"],
    tooltip: [
      "plots_descriptions.DISPLACEMENT_AMPLITUDE",
      "plots_descriptions.AGGREGATE_NOTE",
    ],
  },
})

export const displacementAmplitudePlotDef: IPlotDef = fp.merge(plotDefBase, {
  view: displacementAmplitudePlotView,
  reqConfig: {
    fields: ["sensors.motion.fatigue.max_displacement"],
    aggregate: ["max"],
    group: ["id", "_field"],
  },
})

const transverseWindSpeedPlotView = fp.merge(lineView, {
  name: "Transverse wind speed",
  nameKey: "TRANSVERSE_WIND_SPEED",
  properties: {
    axes: {
      y: {
        suffix: "m/s",
      },
    },
    fill: ["id", "_field"],
    legendColumns: ["name", "_value", "_time"],
    tooltip: [
      "plots_descriptions.TRANSVERSE_WIND_SPEED",
      "plots_descriptions.AGGREGATE_NOTE",
    ],
  },
})

export const transverseWindSpeedPlotDef: IPlotDef = fp.merge(plotDefBase, {
  view: transverseWindSpeedPlotView,
  reqConfig: {
    fields: ["sensors.motion.aeolian.transverse_wind_speed_estimate"],
    aggregate: ["mean"],
    group: ["id", "_field"],
  },
})

const batteryLevelPlotView = fp.merge(lineView, {
  name: "Battery",
  nameKey: "BATTERY",
  properties: {
    axes: {
      y: {
        bounds: ["0", "100"],
        suffix: "%",
      },
    },
    fill: ["id", "_field"],
    legendColumns: ["name", "_value", "_time"],
    tooltip: ["plots_descriptions.AGGREGATE_NOTE"],
  },
})

export const batteryLevelPlotDef: IPlotDef = fp.merge(plotDefBase, {
  view: batteryLevelPlotView,
  reqConfig: {
    fields: ["power.battery.level"],
    aggregate: ["mean"],
    group: ["id", "_field"],
  },
})

const sagPlotView = fp.merge(lineView, {
  name: "Sag",
  nameKey: "SAG",
  properties: {
    axes: {
      y: {
        suffix: "m",
        base: "",
      },
    },
    fill: ["id", "_field", "result"],
    legendColumns: ["name", "_value", "_time"],
    tooltip: ["plots_descriptions.SAG", "plots_descriptions.AGGREGATE_NOTE"],
  },
})

export const sagPlotDef: IPlotDef = fp.merge(plotDefBase, {
  view: sagPlotView,
  reqConfig: {
    fields: ["derived.sag.value"],
    aggregate: ["max", "mean"],
    group: ["id", "_field"],
  },
})

const currentPlotView = fp.merge(lineView, {
  name: "Current",
  nameKey: "CURRENT",
  properties: {
    axes: {
      y: {
        suffix: "A",
      },
    },
    fill: ["id", "result"],
    legendColumns: ["name", "_value", "_time"],
    tooltip: ["plots_descriptions.AGGREGATE_NOTE"],
  },
})

export const currentPlotDef: IPlotDef = fp.merge(plotDefBase, {
  view: currentPlotView,
  reqConfig: {
    fields: ["derived.current.value"],
    aggregate: ["max", "mean"],
    group: ["id", "_field"],
  },
})

const ampacityAARPlotView = fp.merge(lineView, {
  name: "Ampacity (AAR)",
  nameKey: "AMPACITY_AAR",
  properties: {
    axes: {
      y: {
        suffix: "A",
      },
    },
    fill: ["powerline_id", "result"],
    legendColumns: ["name", "_value", "_time"],
    tooltip: ["plots_descriptions.AMPACITY_AAR", "plots_descriptions.AGGREGATE_NOTE"],
  },
})

export const ampacityAARPlotDef: PowerlinePlotDef = fp.merge(plotDefBase, {
  view: ampacityAARPlotView,
  reqConfig: {
    fields: ["ampacity"],
    aggregate: ["mean"],
    group: ["powerline_id", "_field"],
    measurement: "dlr",
  },
})
