import type { FC } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { FormProvider, useForm } from "react-hook-form"
import Dialog from "@mui/material/Dialog"
import { Stack } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import { selectCurrentOrgId } from "features/store/orgSlice"
import { useCreateGroupMutation } from "features/api"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import CardWithTitle from "widgets/common/CardWithTitle"
import FormButtons from "widgets/common/FormButtons"
import ControlledInput from "../../common/ControlledInput"

interface ICreateGroupPopupProps {
  open: boolean
  onClose: () => void
}

interface ICreateGroupForm {
  group_name: string
}

const CreateGroupPopup: FC<ICreateGroupPopupProps> = ({ onClose, open }) => {
  const { t } = useTranslation()
  const orgId = useSelector(selectCurrentOrgId)
  const methods = useForm<ICreateGroupForm>({
    mode: "all",
    defaultValues: {
      group_name: "",
    },
  })
  const [createGroup, { isLoading: createGroupIsLoading }] = useCreateGroupMutation()

  const onSubmit = (data: ICreateGroupForm) => {
    snackbarMutation({
      mutation: createGroup({
        group_name: data.group_name,
        org_id: orgId as number,
      }).unwrap(),
      getErrorMessage: buildGetErrorMessage(
        t("error.CREATING_ITEM", { item: t("device_groups.DEVICE_GROUP").toLowerCase() }),
      ),
      getSuccessMessage: () => t("device_groups.SUCCESS_ALERT_NEW_GROUP"),
    })
      .then(() => {
        onClose()
      })
      .catch()
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
      <CardWithTitle
        titleKey="device.CREATE_DEVICE_GROUP"
        handleAction={onClose}
        actionIcon={<CloseIcon fontSize="small" color="action" />}
      >
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormProvider {...methods}>
            <Stack mb={2}>
              <ControlledInput
                autoFocus
                variant="outlined"
                name="group_name"
                placeholder={t("device_groups.GROUP_NAME")}
                label={t("device_groups.GROUP_NAME")}
                disabled={createGroupIsLoading}
              />
            </Stack>
            <FormButtons
              buttonText="generic.CREATE"
              onClose={onClose}
              disabled={createGroupIsLoading}
              isLoading={createGroupIsLoading}
            />
          </FormProvider>
        </form>
      </CardWithTitle>
    </Dialog>
  )
}

export default CreateGroupPopup
