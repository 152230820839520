import { useMemo } from "react"
import type { ReactElement, ReactNode } from "react"

import { Stack, Typography, IconButton, Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

interface SectionTitleProps {
  titleKey: string
  infoTooltipContent?: string | ReactElement
  actionPermission?: boolean
  isDoingAction?: boolean
  handleAction?: () => void
  actionIcon?: ReactNode
}

const SectionTitle = ({
  titleKey,
  infoTooltipContent,
  actionPermission = true,
  isDoingAction = false,
  handleAction,
  actionIcon,
}: SectionTitleProps) => {
  const { t } = useTranslation()
  const hasAction = useMemo(() => handleAction && actionIcon, [handleAction, actionIcon])

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      borderBottom={1}
      borderColor={(theme) => theme.palette.neutral[400]}
      px={2.5}
      py={1.5}
      height={"45px"}
    >
      <Stack direction={"row"} gap={1}>
        <Typography variant="h5" component="h5">
          {t(titleKey)}
        </Typography>
        {infoTooltipContent && (
          <Tooltip
            title={<>{infoTooltipContent}</>}
            enterTouchDelay={0}
            placement="bottom-start"
            arrow
          >
            <InfoOutlinedIcon fontSize="small" color="primary" />
          </Tooltip>
        )}
      </Stack>
      {actionPermission && hasAction && !isDoingAction && (
        <IconButton size="small" color="primary" onClick={handleAction}>
          {actionIcon}
        </IconButton>
      )}
    </Stack>
  )
}

export default SectionTitle
