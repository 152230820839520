import { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import fp from "lodash/fp"

import {
  selectAuditTrailDateRange,
  selectDeviceDateRange,
  selectEventsDateRange,
  selectPowerlineDateRange,
  updateAuditTrailDateRange,
  updateDeviceDateRange,
  updateEventsDateRange,
  updatePowerlineDateRange,
} from "features/store/dateRangeSlice"
import type { DateRange } from "types/date.types"
import { formatSearchParams } from "helpers/utils/dateUtils"

const isDateRangeDirty = (
  searchParams: URLSearchParams,
  dateRange: DateRange | null,
): boolean => {
  const range = searchParams.get("range")
  const fromDate = searchParams.get("fromDate")
  const toDate = searchParams.get("toDate")
  const dateRangeParams = { range, fromDate, toDate }

  if (range && range !== "custom" && range !== dateRange?.range) {
    return true
  } else if (range && range === "custom" && !fp.isEqual(dateRangeParams, dateRange)) {
    return true
  } else {
    return false
  }
}

const useDateRange = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const deviceDateRange = useSelector(selectDeviceDateRange)
  const eventsDateRange = useSelector(selectEventsDateRange)
  const auditTrailDateRange = useSelector(selectAuditTrailDateRange)
  const powerlineDateRange = useSelector(selectPowerlineDateRange)

  const dispatchDeviceDateRange = useCallback(
    (dateRange: DateRange) => {
      dispatch(updateDeviceDateRange(dateRange))
    },
    [dispatch],
  )
  const dispatchEventsDateRange = useCallback(
    (dateRange: DateRange) => {
      dispatch(updateEventsDateRange(dateRange))
    },
    [dispatch],
  )
  const dispatchAuditTrailDateRange = useCallback(
    (dateRange: DateRange | null) => {
      dispatch(updateAuditTrailDateRange(dateRange))
    },
    [dispatch],
  )
  const dispatchPowerlineDateRange = useCallback(
    (dateRange: DateRange | null) => {
      dispatch(updatePowerlineDateRange(dateRange))
    },
    [dispatch],
  )

  const deviceSearchParams = useMemo(
    () => formatSearchParams(deviceDateRange),
    [deviceDateRange],
  )
  const eventsSearchParams = useMemo(
    () => formatSearchParams(eventsDateRange),
    [eventsDateRange],
  )
  const auditTrailSearchParams = useMemo(
    () => formatSearchParams(auditTrailDateRange),
    [auditTrailDateRange],
  )
  const powerlineSearchParams = useMemo(
    () => formatSearchParams(powerlineDateRange),
    [powerlineDateRange],
  )

  const isDeviceDateRangeDirty = useMemo(() => {
    return isDateRangeDirty(searchParams, deviceDateRange)
  }, [deviceDateRange, searchParams])

  const isDeviceDateRangeValid = useMemo(
    () => !!deviceDateRange && !isDeviceDateRangeDirty,
    [deviceDateRange, isDeviceDateRangeDirty],
  )

  const isEventsDateRangeDirty = useMemo(() => {
    return isDateRangeDirty(searchParams, eventsDateRange)
  }, [eventsDateRange, searchParams])

  const isEventsDateRangeValid = useMemo(
    () => !!eventsDateRange && !isEventsDateRangeDirty,
    [eventsDateRange, isEventsDateRangeDirty],
  )

  const isAuditTrailDateRangeDirty = useMemo(() => {
    return isDateRangeDirty(searchParams, auditTrailDateRange)
  }, [auditTrailDateRange, searchParams])

  const isAuditTrailDateRangeValid = useMemo(
    () => !!auditTrailDateRange && !isAuditTrailDateRangeDirty,
    [auditTrailDateRange, isAuditTrailDateRangeDirty],
  )

  const isPowerlineDateRangeDirty = useMemo(() => {
    return isDateRangeDirty(searchParams, powerlineDateRange)
  }, [powerlineDateRange, searchParams])

  const isPowerlineDateRangeValid = useMemo(
    () => !!powerlineDateRange && !isPowerlineDateRangeDirty,
    [powerlineDateRange, isPowerlineDateRangeDirty],
  )

  return {
    deviceDateRange,
    eventsDateRange,
    auditTrailDateRange,
    powerlineDateRange,
    dispatchDeviceDateRange,
    dispatchEventsDateRange,
    dispatchAuditTrailDateRange,
    dispatchPowerlineDateRange,
    deviceSearchParams,
    eventsSearchParams,
    auditTrailSearchParams,
    powerlineSearchParams,
    isDeviceDateRangeValid,
    isEventsDateRangeValid,
    isAuditTrailDateRangeValid,
    isPowerlineDateRangeValid,
  }
}

export default useDateRange
