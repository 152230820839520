import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {
  Box,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material"

import { Paragraph } from "pages/documentation/api/wrappers"
import Title from "widgets/common/Title"
import LoadingButton from "widgets/common/LoadingButton"
import { selectCurrentUser, updateTerms } from "features/store/authSlice"
import { useAcceptUserTermsMutation } from "features/api"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import {
  DEFAULT_TARGET,
  PREVIOUS_LOCATION_KEY,
  SECTIONS_BORDER_RADIUS,
} from "helpers/utils/constants"

interface TermsAndConditionsProps {
  actionable?: boolean
}

const TermsAndConditions = ({ actionable = false }: TermsAndConditionsProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const username = useSelector(selectCurrentUser)
  const [readAndAgreed, setReadAndAgreed] = useState(false)
  const [acceptUserTerms, { isLoading }] = useAcceptUserTermsMutation()

  const handleAccept = async () => {
    await snackbarMutation({
      mutation: acceptUserTerms({ username: String(username) }).unwrap(),
      getErrorMessage: buildGetErrorMessage(
        t("error.UPDATING_ITEM", {
          item: t("generic.TERMS_AND_CONDITIONS").toLowerCase(),
        }),
      ),
      getSuccessMessage: () =>
        t("success.UPDATING_ITEM", {
          item: t("generic.TERMS_AND_CONDITIONS"),
          count: 2,
          context: "male",
        }),
    }).then(() => {
      dispatch(updateTerms({ terms: "up-to-date" }))
      const target = localStorage.getItem(PREVIOUS_LOCATION_KEY) ?? DEFAULT_TARGET
      navigate(target)
    })
  }

  const content = (
    <>
      <Title text="Sentrisense Spain S.L - Terms of Service" styles={{ mb: "0.5rem" }} />
      <Divider />
      <Typography variant="h4" component="h4" my="0.5rem">
        1. General
      </Typography>
      <Paragraph>
        Sentrisense Spain SL, headquartered in Numancia 8, 08029, Barcelona, Spain, NIF
        B56291339 (hereinafter “Sentrisense”) provides its services (hereinafter
        “Services”) on the basis of the following terms of service (hereinafter “Terms”)
        to the customer (hereinafter “Customer”) as identified in the relevant Order Form
        (as described below) and/or through access on the Sentrisense Dashboard (as
        described below). Sentrisense Services are only available to business Customers
        and not to consumers. When accepting these Terms and ordering the Sentrisense
        Services, the Customer confirms that he is not ordering as a consumer but as a
        business customer and for business purposes and shall therefore always state his
        company/business details. Sentrisense and Customer are hereinafter also referred
        to as “Party” or together as “Parties.”{" "}
      </Paragraph>
      <Typography variant="h4" component="h4" mb="0.5rem">
        2. Contract Conclusion and Contract Structure
      </Typography>
      <Paragraph>
        2.1. The contract for Sentrisense Services (hereinafter “Contract”) is concluded
        by Customer placing an order (hereinafter “Order”) with Sentrisense through an
        order form (hereinafter “Order Form”) made available by Sentrisense in electronic
        format or, if available, through means of online contracting, and Sentrisense
        having accepted such Order in text form by e-mail to the mail address of Customer
        indicated in the Order or, if available, through means of online contracting. Any
        offers or indications by Sentrisense on price, quantity, delivery time and
        availability are only binding when accepted by Sentrisense through acceptance of
        the Order, and it is understood that Sentrisense is not obliged to accept a
        Customer Order. Each Customer Order accepted by Sentrisense, and each activation
        of a device (as defined below), hereunder generate a separate Contract, in the
        latter case under the relevant Order.
      </Paragraph>
      <Paragraph>
        2.2. Sentrisense makes available the Order Form to Customer by email upon request
        of Customer. Customer may place his Order by filling out the Order Form and
        submitting it to Sentrisense by sending it per e-mail as attachment, or by sending
        the hardcopy through registered mail or courier to Sentrisense.
      </Paragraph>{" "}
      <Paragraph>
        2.3. When placing an Order, the Customer confirms that he is not ordering the
        Sentrisense Services as a consumer but as a business and for business purposes,
        and shall therefore always state his VAT number, and/or his company registration
        number, and/or his company/business details.
      </Paragraph>
      <Paragraph>
        2.4. The Contract between Sentrisense and Customer includes the following
        documents as an integral part of the Contract with the following order of
        precedence in case of conflicting provisions:
        <ul style={{ marginLeft: "1rem" }}>
          <li>Order as accepted by Sentrisense </li>
          <li>These Terms </li>
          <li>Service Description </li>
        </ul>
        These Terms and the above documents apply exclusively. Any terms and conditions or
        procurement terms of Customer are not binding or applicable to Sentrisense, even
        if Sentrisense does not expressly reject such terms and conditions or procurement
        terms.
      </Paragraph>
      <Paragraph>
        2.5. Any subsequent Orders of Customer are governed by the current version of
        these Terms. Any agreements containing individual terms deviating from these Terms
        are subject to our prior written consent.
      </Paragraph>
      <Typography variant="h4" component="h4" mb="0.5rem">
        3. Services provided by Sentrisense
      </Typography>
      <Paragraph>
        3.1. General 3.1.1. Sentrisense provides sensors for monitoring power lines
        (hereinafter Sensors) telecommunications and other Services for the Internet of
        Things (hereinafter “IoT”) as detailed in the Contract documents listed in clause
        2.4, and as agreed in the Order accepted by Sentrisense pursuant to clause 2.1
        Other products and services than those explicitly agreed in the Order between
        Sentrisense and Customer are not covered by the Contract and require a separate
        agreement between the Parties.
      </Paragraph>
      <Box ml="1rem">
        <Paragraph>
          3.1.2. The Services are provided through (i) a cloud-based software platform
          (hereinafter “Sentrisense Dashboard”) that enables management and integration of
          Customer devices and systems by way of the Sentrisense API, and (ii) the
          provision of connectivity for the Customer devices. With respect to
          connectivity, Sentrisense supplies M2M SIM cards ((hereinafter “SIM Cards”) and
          data transmission to and from the Sentrisense Dashboard on the basis of network
          services provided by mobile network operators or mobile service providers.
          Geographic coverage for the connectivity is limited to the countries and regions
          as provided in the Tariff and Rate Zone Documentation. With respect to the
          Sentrisense Dashboard, the Services are technically confined to the location of
          the servers and software that operate the Sentrisense Dashboard.
        </Paragraph>
        <Paragraph>
          3.1.3. Where required, the Services consist of a configuration phase during
          which the Sentrisense Dashboard is configured for the usage by the Customer and
          receipt of the ongoing Services, and an operation phase during which the ongoing
          Services are being provided to and received by Customer. Where a configuration
          phase occurs, the resulting configuration of the Sentrisense Dashboard
          determines the scope of the Services in addition to the Contract documents
          pursuant to clause 2.4.
        </Paragraph>
        <Paragraph>
          3.1.4. Sentrisense procures products and services (in particular hardware
          sensors and software and telecommunications services) through third parties
          (hereinafter “Suppliers“) to provide the Services and is entitled to engage
          third parties in providing the Services (hereinafter “Subcontractors“).
        </Paragraph>
        <Paragraph>
          3.1.5. To the extent required for receiving the Services, Sentrisense herewith
          grants to Customer, against payment of the agreed remuneration, and for the term
          of the Contract, the non-exclusive, non-transferable, non-assignable and not
          sub-licensable usage right in the Sentrisense Dashboard.
        </Paragraph>
      </Box>
      <Paragraph>
        3.2. Service Level Obligations 3.2.1. When providing the Services, Sentrisense
        shall meet the Service Level Obligations agreed with the Customer. If no other
        Service Level Obligations have been agreed, the Obligations specified in the annex
        - Standard Service Level Obligations apply.
      </Paragraph>
      <Box ml="1rem">
        <Paragraph>
          3.2.2. Customer is entitled to upgrade or downgrade the agreed Service Level
          Obligations in accordance with the provisions of the Service Level Obligations
          annex agreed with Sentrisense.
        </Paragraph>
        <Paragraph>
          3.2.3. In case the Customer upgrades or downgrades the Service Level
          Obligations, the Tariff and Rate Zone Documentation and Service Description for
          the selected Service Level Obligations apply.
        </Paragraph>
      </Box>
      <Paragraph>
        3.3 SIM Cards and Sensors 3.3.1. Unless agreed otherwise in writing between the
        Parties, Sentrisense provides the SIM Cards required for the use of Sentrisense
        Services to Customer.
      </Paragraph>
      <Box ml="1rem">
        <Paragraph>
          3.3.2. Delivery dates communicated by Sentrisense to Customer are estimates and
          not binding, unless explicitly communicated as binding by Sentrisense. In the
          event of any delay on binding delivery dates, Sentrisense shall have a grace
          period of at least three (3) weeks to remedy the delay. Partial deliveries shall
          be accepted by the Customer.
        </Paragraph>
        <Paragraph>
          3.3.3. When a Customer requests shipping of the Sensors, the risk of accidental
          loss or deterioration passes to the Customer at the moment of the dispatch to
          the Customer, but no later than the Sensors are leaving the premises of
          Sentrisense or its suppliers.
        </Paragraph>
        <Paragraph>
          3.3.4. In the event that, without justification, Customer refuses acceptance of
          the Sensors despite a grace period of fourteen (14) days set by Sentrisense, or
          if Customer finally declares not to accept the Sensors, or if Customer declares
          withdrawal from the relevant Contract, Sentrisense may agree to a mutual
          termination of the relevant Contract subject to a lump sum payment by Customer
          of 30 % of the net goods value under the respective Order as compensation.
        </Paragraph>
      </Box>
      <Typography variant="h4" component="h4" mb="0.5rem">
        4. Contract Term, Termination
      </Typography>
      <Paragraph>
        4.1. The Contract term begins upon acceptance of the Order by Sentrisense pursuant
        to clause 2.1 and its duration is one year. Any minimum Contract term shall be
        determined in the Contract through the relevant Tariff chosen by the Customer.
        After expiry of any minimum Contract term, or in the absence of a minimum Contract
        term, the Contract shall continue, or be concluded, as the case may be, for an
        indefinite term. The Contract may be terminated by either Party by giving one (1)
        month prior written (text form) notice to the end of a calendar month, but no
        earlier than to the end of the calendar month in which any minimum Contract term
        expires.
      </Paragraph>
      <Paragraph>
        4.2. Other, additional or extended minimum Contract terms may apply in case of
        adding or activating Tariffs pursuant to the specific Tariff conditions as
        described in the Tariff and Rate Zone Documentation. Tariff changes, adding
        Services and/or Tariffs by the Customer during the Contract term can extend or
        shorten the remaining Contract term, if agreed in the respective Order pursuant to
        the procedure foreseen in clause 2.1
      </Paragraph>
      <Paragraph>
        4.3. During any minimum term the ordinary termination is excluded. However, the
        Parties may terminate the Contract with immediate effect upon notice in writing to
        the other Party, in particular, if the other party commits a material breach of
        the Contract and fails to remedy such breach within thirty (30) days of receipt of
        a written notice giving full particulars of such material breach and requesting
        that it be remedied. Sentrisense may furthermore terminate the Contract with
        immediate effect, if Customer is in default with payment of a significant invoiced
        amount (i.e. at least two monthly payments or an amount equal to two average
        monthly invoices).
      </Paragraph>
      <Paragraph>
        4.4. Termination notices shall be given in text form, i.e. by letter, email or
        fax.
      </Paragraph>
      <Paragraph>
        4.5. Services are provided until the last day of the Contract term and the
        Customer is obliged to pay all fees and charges incurred until that time. Upon
        termination, or expiry, of the Contract, Customer shall be obliged to cease usage
        of the Services. Customer shall block access to any online tools, in particular
        the Sentrisense Dashboard, it being understood that Sentrisense shall also be
        entitled to switch off any online access.
      </Paragraph>
      <Typography variant="h4" component="h4" mb="0.5rem">
        5. Use of the Services by Customer and Customer Obligations
      </Typography>
      <Paragraph>5.1. General Principles</Paragraph>
      <Box ml="1rem">
        <Paragraph>
          5.1.1. Customer shall use the Services solely in accordance with the provisions
          of the Contract and for lawful purposes and shall not give unlawful directions
          to Sentrisense or directions that would cause Sentrisense to violate any legal
          provisions. In particular, Customer shall not use or attempt to use, or allow or
          tolerate to use the Services in connection with any action that is prohibited by
          any applicable export control and economic sanction regulations, including those
          of the USA, the UK, and the EU/EEA. Customer shall notify Sentrisense in writing
          (text form) immediately upon becoming aware of or suspecting such activity in
          the course its own services. Furthermore, Customer shall not use the Services or
          features of the Services (or SIM Cards) in a manner that has not been agreed in
          the Contract. Services and features, such as Short Message Service (hereinafter:
          &quot;SMS&quot;) to any telephone number, voice communication to any telephone
          number as well as the forwarding of voice calls, use as voicemail service or the
          use of value-added services can only be used if explicitly agreed in the
          Contract. In the event that Sentrisense reasonably determines that any provision
          of this clause has been or may be violated, Customer will cooperate fully with
          Sentrisense investigation to examine and resolve the issue. Furthermore,
          Customer shall indemnify Sentrisense in full and on demand for any claims,
          costs, demands, expenses or other liabilities that Sentrisense may incur whether
          actual or contingent, as a result of any use of the Services in violation of the
          Contract.
        </Paragraph>

        <Paragraph>
          5.1.2. Customer is responsible for the use and interpretation of the information
          and Services provided by Sentrisense. As data storage is not provided by
          Sentrisense, unless explicitly agreed otherwise, and irrespective of whether
          such agreement exists, Customer is also responsible for regularly making back-up
          copies of his data that is processed or used in conjunction with the Services.
        </Paragraph>
        <Paragraph>
          5.1.3. To the extent that Customer concludes contracts with third parties
          (including general terms and conditions with end customers) that impair or
          hinder the provisioning of the Services by Sentrisense, Sentrisense shall be
          released from fulfilling its contractual obligations.
        </Paragraph>
        <Paragraph>
          5.1.4. Where Customer resells the Sentrisense Services to third parties, in
          particular to end customers, and without prejudice to any claim that Customer
          may have against Sentrisense for Sentrisense&apos;s failure to provide the
          Services as agreed, any claim or demand arising from or related to a third party
          customer or other user of the Services resold by Customer shall be a matter
          between Customer and such third party. The Customer shall indemnify Sentrisense
          in full and on demand for any claims, costs, demands, expenses or other
          liabilities that Sentrisense may incur whether actual or contingent as a result
          of any claim or demand brought or alleged by such third party customer or user,
          or by any other third party as a result of the use of the third party customer
          or user of the Services. Customer shall be responsible for the actions and
          omissions of third-party customers or users the same way as for its own actions
          and omissions.
        </Paragraph>
        <Paragraph>
          5.1.5. Customer shall provide Sentrisense with all information, data, documents
          and material that are required or that Customer considers required or useful for
          the rendering of the Services by Sentrisense. Sentrisense shall not be obliged
          to review material, data, documents and information provided by Customer or
          third parties (except Subcontractors and Suppliers of Sentrisense) for
          inconsistencies, mistakes or omissions.
        </Paragraph>
        <Paragraph>
          5.1.6. Customer shall comply with all obligations, as set forth in the Contract,
          in a complete, orderly and timely manner, and at Customer&apos;s own cost.
          Should Customer fail to fulfill its obligations in a complete, orderly and
          timely manner, such failure shall release Sentrisense, for the duration of the
          failure, from such performance hereunder that requires compliance by Customer
          with his obligations, or where Customerr&apos;s failure would result in
          unreasonable additional efforts for Sentrisense. Service Level Obligations shall
          be suspended for the duration of the failure or default plus a reasonable
          ramp-up period for restoration.
        </Paragraph>
      </Box>
      <Paragraph>5.2. SIM Cards</Paragraph>
      <Box ml="1rem">
        <Paragraph>
          5.2.1. SIM Cards provided by Sentrisense may only be used in conjunction with
          the Sentrisense Services and not otherwise. SIM Cards shall only be used by the
          Customer in a physical manner and shall be activated by Sentrisense personnel
          only.
        </Paragraph>
        <Paragraph>
          5.2.2. Customer is obliged:
          <ul style={{ marginLeft: "1rem" }}>
            <li>
              to notify Sentrisense without undue delay of any change of name, change of
              address, changes of headquarters, email address and other inputs significant
              for the Contract;{" "}
            </li>
            <li>
              to protect the SIM Card from abuse and shall inform Sentrisense of any abuse
              by giving full particulars of the impacted SIM Card(s) immediately after
              obtaining knowledge thereof. Such information can be provided by phone once
              recorded in Sentrisenser&apos;s service-ticketing-system, or by sending an
              email to the service hotline. In the event of loss of control over the SIM
              Card attributable to Customer, Customer remains obliged to pay the fees
              arising from use of the SIM Card by third parties until suspension of the
              SIM Card by the Customer using the Sentrisense Dashboard or by explicitly
              requesting such suspension from Sentrisense in writing.{" "}
            </li>
            <li>
              to refrain from accessing the SIM Card file system, from using the SIM Cards
              for SMS SIM Boxing, from generating any (signaling) traffic with non-
              activated or non-allocated SIM cards, and from activities with similar
              effect (Phantom SIM Cards), and to tolerate permanent physical deactivation
              of the affected SIM Cards by Sentrisense without liability or replacement
              obligation of Sentrisense in the event of SIM Card usage in violation of the
              provisions in this Contract.
            </li>
          </ul>
        </Paragraph>
      </Box>
      <Paragraph>5.3. FREE Evaluation devices and services</Paragraph>
      <Box ml="1rem">
        <Paragraph>
          5.3.1. Sentrisense provides potential customers (hereinafter
          &quot;Customer&quot; or &quot;Customers&quot;) a FREE evaluation device
          (hereinafter “FREE Evaluation Kit”) based on the terms in this clause 5.3 and
          its subclauses through access on the Sentrisense Dashboard.
        </Paragraph>
        <Paragraph>
          5.3.2.The Sentrisense FREE Evaluation Kit is primarily intended to offer the
          Customer the possibility to test Sentrisense Services on a free, non-remunerated
          basis.
        </Paragraph>
        <Paragraph>
          5.3.3. Customer shall use the FREE Evaluation Kit solely for the purpose of
          testing the compatibility of the Sentrisense Services with the Customerr&apos;s
          products and/or connectivity solutions. 5.3.4. Customer is obliged to
          <ul style={{ marginLeft: "1rem" }}>
            <li>not use FREE Evaluation Kit for any fraudulent or unlawful purposes; </li>
            <li>
              not use FREE Evaluation Kit to commit or facilitate the commission of a
              crime, or other unlawful act;
            </li>{" "}
            <li>
              not act in any way, which may affect or impair the operation of any network
              used or operated by Sentrisense;{" "}
            </li>
            <li>
              only use the FREE Evaluation Kit for its own business use and must not sell,
              resell, lease, sub-lease or otherwise deal commercially with the FREE
              Evaluation Kit;{" "}
            </li>
            <li>
              only use the FREE Evaluation Kit for the purposes and in the manner
              expressly permitted by clause ;
            </li>{" "}
            <li>
              only use the FREE Evaluation Kit in accordance with all applicable laws and
              regulations of its home nation, the country in which it resides, or the
              country in which it is present whilst using the FREE Evaluation Kit;{" "}
            </li>{" "}
            <li>to comply with obligations set forth in clause 5.2. to these Terms. </li>
          </ul>
        </Paragraph>
        <Paragraph>
          5.3.5. The FREE Evaluation Kit is provided on an &quot;as is&quot; and &quot;as
          available&quot; basis. Sentrisense does not warrant or guarantee a fault free
          FREE Evaluation Kit service and gives no warranties or guarantees as to network
          coverage, quality or availability.
        </Paragraph>
        <Paragraph>
          5.3.6. In the event that Sentrisense reasonably determines that any provision of
          this clause 5.3 has been or may be violated, Customer will cooperate fully with
          Sentrisense&apos;s investigation to examine and resolve the issue. Furthermore,
          Customer shall indemnify Sentrisense in full and on demand for any claims,
          costs, demands, expenses or other liabilities that Sentrisense may incur whether
          actual or contingent, as a result of any use of the FREE Evaluation Kit in
          violation of these terms.
        </Paragraph>
      </Box>
      <Paragraph>5.4. Sentrisense API and the Sentrisense Dashboard</Paragraph>
      <Box ml="1rem">
        <Paragraph>
          5.4.1. The Customer is responsible for the technical set-up and interoperability
          of his systems with Sentrisense Services, in particular the Sentrisense API and
          Sentrisense Dashboard. The implementation shall be in accordance with the API
          specifications and policies (in particular &apos;Sentrisense RestAPI
          specification&apos;) provided by Sentrisense.
        </Paragraph>
        <Paragraph>
          5.4.2. The Customer is responsible for the connectivity of his systems to the
          Sentrisense Dashboard through a suitable Internet access that enables him to
          access the Sentrisense Dashboard at the location of the servers and software
          that operate the Sentrisense Dashboard.
        </Paragraph>
        <Paragraph>
          5.4.3. The Customer is responsible for the security of his hardware, and the
          security and confidentiality of his data, as well as the access data, which he
          uses for the access to the Sentrisense Dashboard.
        </Paragraph>
      </Box>
      <Paragraph>
        <Typography variant="h4" component="h4" mb="0.5rem">
          6. Prices and Invoicing
        </Typography>
        6.1. All prices are net of freight, packaging and ancillary costs, such as customs
        and import duties, and applicable statutory Value Added Tax (&quot;VAT&quot;) in
        the amount as applicable at the date of invoicing.
      </Paragraph>
      <Paragraph>
        6.2. For the provision of Sensors, Sentrisense issues the invoice upon acceptance
        of the Customer Order pursuant to clause 2.1.
      </Paragraph>
      <Paragraph>
        6.3. Agreed monthly fees or minimum fees are invoiced monthly in advance.
      </Paragraph>
      <Paragraph>
        6.4. Variable charges, in particular agreed usage fees will normally be invoiced
        within three (3) working days from the end of the previous month. Sentrisense
        reserves the right to invoice usage fees for such month also in later invoices, if
        the fees have not been invoiced in the following month.
      </Paragraph>
      <Paragraph>6.5. All invoiced amounts are payable immediately.</Paragraph>
      <Paragraph>6.6. Invoices are provided to the Customer electronically.</Paragraph>
      <Paragraph>
        <Typography variant="h4" component="h4" mb="0.5rem">
          7. Payment, Security, Disputes
        </Typography>
        7.1 Sentrisense&apos;s Services are generally provided on the basis of advance
        payments for which the Customers are invoiced on a regular basis and for which a
        true-up is made by Sentrisense on a monthly basis pursuant to the invoices issued
        in accordance with clause 6. Invoices for advance payments are payable immediately
        upon receipt and without deduction.
      </Paragraph>
      <Box ml="1rem">
        <Paragraph>
          7.1.1. Payments via wire transfer have to be made in Euro to the bank account
          named in the invoice. The Customer may also authorize Sentrisense to regularly
          charge advance payments by direct debit. Payments may also be made on a regular
          basis via Sentrisense&apos;s credit card facility as indicated on the
          Sentrisense Dashboard. The Customer is responsible to ensure that the credit
          card data stored in Sentrisense credit card facility is up to date. Where
          Sentrisense is unable to withdraw funds from the Customer&apos;s credit card
          Sentrisense will notifyCustomer.
        </Paragraph>
        <Paragraph>
          7.1.2. Sentrisense may withhold the Services until payment of the invoiced
          advance amount is received in full. Any applicable bank charges shall be borne
          by the Customer.
        </Paragraph>
      </Box>
      <Paragraph>
        7.2. Sentrisense may agree to the Services being paid by Customer without advance
        payments pursuant to clause 7.1. When agreeing to such post-paid Service
        providing, the provisions in clauses 7.1.1 to 7.1.3 apply to the payment of the
        invoices issued pursuant to clause 6 accordingly. Furthermore, Sentrisense can
        request, in its sole and reasonable discretion, that Customer provides adequate
        security in order to secure due and future payments using one or more of the
        following methods:
      </Paragraph>
      <Box ml="1rem">
        <Paragraph>
          7.2.1. Credit Limit Sentrisense may provide Customer with an initial Credit
          Limit amount (hereinafter: &quot;Credit Limit&quot;) and will notify Customer of
          this in writing. Sentrisense may change the Credit Limit in its reasonable
          discretion by giving twenty-four (24) hours prior written (text form) notice.
          Sentrisense will send this notice by e-mail notification. Sentrisense may
          suspend the Services without prior notice if the Credit Limit is exhausted,
          meaning that the sum of (i) the total invoiced amounts, which remain unpaid,
          plus (ii) the unbilled but accrued usage across all Contracts, has exceeded the
          then current Credit Limit.
        </Paragraph>
        <Paragraph>
          7.2.2. Bank Guarantee or Deposit Instead of, or in addition to a Credit Limit,
          Sentrisense may require a deposit or a bank guarantee from Customer, in an
          amount and form that is satisfactory to Sentrisense. Failure to provide the
          deposit or bank guarantee as requested shall entitle Sentrisense to suspend the
          Services.
        </Paragraph>
      </Box>
      <Paragraph>
        7.3. In the event of payment default, Sentrisense is entitled to charge default
        interest in the amount of nine percent (15 %) per annum above the applicable base
        interest rate of the European Central Bank from the due date for payment until
        payment is made in full, whether before or after judgement. Sentrisense&apos;s
        right to claim a higher damage caused by the default remains unaffected.
      </Paragraph>
      <Paragraph>
        7.4. Objections against the invoiced amounts shall be made in good faith to
        Sentrisense by email (mail to: info@sentrisense.com) within thirty (30) calendar
        days from receipt of the invoice in writing (text form) and in a substantiated
        manner, if the event giving rise to the objection has become known within this
        period. Upon expiry of one (1) year from receipt of the invoice, objections shall
        be excluded. Objections only entitle the Customer to withhold payment only to the
        extent the invoiced amount has been disputed as required above.
      </Paragraph>
      <Paragraph>
        <Typography variant="h4" component="h4" mb="0.5rem">
          8. Suspension
        </Typography>
        8.1. Without prejudice to other rights Sentrisense may have under this Contract or
        otherwise, Sentrisense is entitled to wholly or partly suspend the Services
        (hereinafter ”Suspension”), including the physical deactivation of a SIM Card
        and/or access to the Sentrisense Dashboard in case of, but not limited to, the
        following events, without liability towards the Customer:
      </Paragraph>
      <Box ml="1rem">
        <Paragraph>
          8.1.1. The Customer is in default with payments, a Credit Limit has been
          exhausted, or Customer has violated other provisions relating to payment (clause
          7);
        </Paragraph>
        <Paragraph>
          8.1.2. Sentrisense monitors a substantial increase in volumes and use of the
          Service, and consequently the Customer&apos;s liability towards Sentrisense is
          in Sentrisense&apos;s reasonable opinion justifying the assumption that the
          Customer will not pay the applicable Tariffs;
        </Paragraph>
        <Paragraph>
          8.1.3. An order for suspension of the Services by a competent court or
          regulatory authority;
        </Paragraph>
        <Paragraph>
          8.1.4. Any threat to the technical facilities and/or network of Sentrisense
          (including, in particular, the Sentrisense Dashboard), or one or more of its
          network service providers, that is, with high probability and/or in
          Sentrisense&apos;s reasonable opinion, caused by the Customer&apos;s use of the
          Service;
        </Paragraph>
        <Paragraph>
          8.1.5. Sentrisense obtains secured information that the SIM Card usage caused or
          causes violations of applicable laws or violates the provisions in clauses 5.2
          or 5.5.2;
        </Paragraph>
        <Paragraph>
          8.1.6. Customer fails to provide Sentrisense with a valid business address and
          email address and any other essential information reasonably requested by
          Sentrisense in accordance with the Contract;
        </Paragraph>
        <Paragraph>
          8.1.7. Customer continues to violate the provisions of the Contract, despite a
          single written (text form) request sent to him by letter, email or courier, to
          remedy the violation.
        </Paragraph>
      </Box>
      <Paragraph>
        8.2. The Customer remains obliged to pay fixed and any usage-based charges, in
        particular monthly fees, minimum fees, etc. during any Service Suspension for
        causes attributable to Customer.
      </Paragraph>
      <Typography variant="h4" component="h4" mb="0.5rem">
        9. Warranty
      </Typography>
      <Paragraph>9.1. General</Paragraph>
      <Box ml="1rem">
        <Paragraph>
          9.1.1 Sentrisense&apos;s obligations to remedy Service defects regulated in the
          following provisions. Sentrisense shall only be liable for damages and efforts
          due to Service defects or due to any performance which is not in compliance with
          the contractually agreed specifications within the limitations of clause 10
          hereof. Subject to the following provisions, any further rights and claims of
          Client due to Service defects are excluded.
        </Paragraph>
        <Paragraph>
          9.1.2. Sentrisense warrants in accordance with that the Services essentially
          comply with the specifications agreed in the Contract. Sentrisense does not
          warrant that the Services are fit for Customer&apos;s intended purposes.
          Customer shall notify Sentrisense in writing (text form) of any defects without
          undue delay.
        </Paragraph>
        <Paragraph>
          9.1.3. In the event of Service defects, Sentrisense provides a warranty by way
          of remediation in that Sentrisense, in its discretion, either provides to
          Customer a new or error free service (including replacement of defective
          components) or rectifies the defect. Defect rectification may also consist of
          showing to or providing Customer with reasonable possibilities that avoid the
          effects of the defect.
        </Paragraph>
        <Paragraph>
          9.1.4. Customer claims shall be excluded in the event of an insignificant
          deviation of the Services from the agreed specifications or in the event of
          insignificant impairments of fitness for the contractually agreed purpose of the
          Services. Any specifications and product descriptions shall not be construed as
          guarantee, unless separately and explicitly agreed in writing and signed by the
          authorized representatives of the Parties.
        </Paragraph>
        <Paragraph>
          9.1.5. Customer may only claim further statutory rights or claim damages and/or
          efforts within the limitations of clause 10, (i) if after unsuccessful expiry of
          a first period to rectify the defect Sentrisense has failed with a second
          rectification effort within a reasonable period of time (typically four (4)
          weeks), or (ii) after Sentrisense has failed with a reasonable number of
          remedial, replacement or workaround efforts. Customer may then terminate the
          Contract for cause pursuant to clause 4.3, provided that the period of time for
          the second rectification effort has been at least thirty (30) calendar days. Any
          rights of rescission of Customer are replaced by this termination right. Any
          self-rectification by Customer is excluded. Where the annex Service Level
          Obligations provides for service credits in the event of defects, any rights to
          reduce the remuneration are then exclusively and finally governed by the annex
          Service Level Obligations.
        </Paragraph>
        <Paragraph>
          9.1.6. Modifications or extensions of the Services and modifications of the
          Customer systems or end devices, as well as interferences with the Sentrisense
          Dashboard, carried out by Customer, or third parties commissioned or tolerated
          by, Customer cancel all warranty obligations of Sentrisense, unless Customer can
          establish that the modification or extension has not caused the defect in the
          Services. Sentrisense is not responsible for defects of the Services that are
          caused by improper handling or usage of the Sentrisense API or Sentrisense
          Dashboard, by the use of improper equipment by Customer or by failures in the
          obligations of Customer.
        </Paragraph>
        <Paragraph>
          9.1.7. If Sentrisense incurs effort in the defect detection or rectification for
          which Sentrisense is not responsible due to the foregoing provisions, then
          Sentrisense may charge this additional effort to Customer pursuant to the then
          current hourly, or daily rates, of Sentrisense personnel on a time & material
          basis.
        </Paragraph>
      </Box>
      <Paragraph>
        9.2. SIM Cards 9.2.1. All SIM Cards provided by Sentrisense are manufactured by
        third parties. Sentrisense obtains the technical specifications of the SIM Card
        from these manufacturers. As is custom in trade, these technical specifications
        are to be considered as approximate and represent no warranted characteristics.
      </Paragraph>
      <Paragraph>
        <Typography variant="h4" component="h4" mb="0.5rem">
          10. Liability
        </Typography>
      </Paragraph>
      <Paragraph>
        10.1. Nothing in this agreement shall limit or exclude the liability of either
        Party:
        <ul style={{ marginLeft: "1rem" }}>
          <li>(a) for fraud or fraudulent misrepresentation; and </li>
          <li>
            (b) any other liability which cannot be limited or excluded by applicable law.
          </li>{" "}
        </ul>
      </Paragraph>
      <Paragraph>
        10.2. Subject to clause 10.1, neither Party shall be liable to the other Party,
        whether in contract, tort (including negligence) or restitution, or for breach of
        statutory duty or misrepresentation or otherwise, for any loss of profits,
        business, contracts, anticipated savings or revenue, loss of or damage to
        goodwill, loss or corruption of data and/or any business special, indirect or
        consequential, losses or damage (whether such losses or damage were foreseen,
        foreseeable, known or otherwise).
      </Paragraph>
      <Paragraph>
        10.3. Subject to clauses 10.1 and 10.2, the total liability of Sentrisense in
        relation to any liability arising under or in connection with this agreement,
        whether arising in contract, tort (including negligence) or restitution, or for
        breach of statutory duty or misrepresentation, or otherwise, shall be limited (i)
        per calendar year to an amount equal to the amount of 50% of the remuneration
        pursuant to the affected Order for that calendar year.
      </Paragraph>
      <Paragraph>
        10.4. Where Sentrisense is providing a publicly available telecommunications
        service, Sentrisense&apos;s liability may also be limited in accordance with
        applicable statutory provisions in the relevant jurisdiction.
      </Paragraph>
      <Paragraph>
        10.5. All claims for damages and efforts become time-barred within one year from
        the statutory commencement of the limitation period.
      </Paragraph>
      <Paragraph>
        10.6. Sentrisense shall not be liable for any direct or indirect damages,
        consequential damages, lost profits, loss of data or any other damages arising
        from causes beyond its control such as labor disputes, fires, severe weather
        conditions, government restrictions or any other natural disaster.
      </Paragraph>
      <Paragraph>
        10.7. Except as expressly set forth in this Agreement, Sentrisense makes no
        warranties, representations, endorsements or guarantees of any kind to the
        Customer or to any third party.
      </Paragraph>
      <Paragraph>
        10.8. Sentrisense shall not be liable in any case for any breakdowns, anomalies or
        deficiencies that may arise from the erroneous, improper or unauthorized
        manipulation of the different elements of its network or the Service, by the
        customer.
      </Paragraph>
      <Paragraph>
        <Typography variant="h4" component="h4" mb="0.5rem">
          11. Data Protection
        </Typography>
      </Paragraph>
      <Paragraph>
        11.1. Within their respective sphere of responsibility, the Parties ensure at
        their respective own cost the compliance with the applicable data protection laws
        when processing personal data as controller or processor, each within the meaning
        of applicable data protection laws.
      </Paragraph>
      <Paragraph>
        11.2. Where the Services qualify as electronic communications services or
        telecommunications services, Sentrisense is acting as a data controller and may
        need to process personal data and other data covered by applicable data protection
        laws (including, but not limited to, Customer contract data, traffic data and
        billing data), and (ii) to disclose it to third parties for the proper fulfillment
        of its contractual and statutory obligations or as permitted by law. In handling
        such data, Sentrisense will act as permitted by law and in compliance with its
        privacy information available through the Sentrisense Dashboard. The same applies
        to the processing of personal data of Customer employees (such as name and contact
        details) by Sentrisense required for proper fulfillment of its contractual and
        statutory obligations, or Customer&apos;s proper receipt of the Services. In
        particular, Sentrisense is entitled to transfer the necessary accounting and
        receivables data, e. g. to a debt collection company in the case of judicial or
        extrajudicial proceedings aiming for the recovery of debt.
      </Paragraph>
      <Paragraph>
        11.3. Where the Services include the processing of personal data by Sentrisense as
        Customer&apos;s data processor (e.g. through the management of Customer devices or
        devices of Customer&apos;s end customers when such management includes access to,
        or handling of, personal data), Customer is the data controller and Sentrisense
        shall process such personal data only in compliance with the written (text form)
        instructions of Customer and as stipulated in a separate data processing agreement
        to be concluded between the Parties for such purpose. Irrespective of provisions
        in such data processing agreement to the contrary, where any Customer instructions
        on data processing or the data processing agreement have an impact on the scope of
        Services agreed in any Contract, Sentrisense shall be entitled to appropriate
        additional remuneration to be agreed between the Parties; should the Parties not
        agree on such remuneration, the then current hourly or daily rates of Sentrisense
        personnel and costs shall apply on a time & material basis.
      </Paragraph>
      <Paragraph>
        <Typography variant="h4" component="h4" mb="0.5rem">
          12. Changes to the Terms, Services and Prices
        </Typography>
      </Paragraph>
      <Paragraph>
        12.1. At any point during the term of the Contract Sentrisense shall be entitled
        to increase the charges payable by Customer in relation to the Services to reflect
        any increase in Sentrisense&apos;s underlying costs of providing those Services
        (including but not limited to costs for network provision, network use and network
        operation, connectivity provision (e.g. for technology, special network access and
        network interconnections, technical service), costs for customer support (e.g. for
        service hotlines, billing and IT systems), personnel and service costs, energy
        costs, general costs (e.g., for administration, marketing, rent, interest), as
        well as fees, expenses, and financial contributions imposed by public
        authorities), by giving Customer 1 (one) month prior written notice of such
        increase.
      </Paragraph>
      <Paragraph>
        12.2. By giving 1 (one) month prior written notice, (in text form) the Services
        (including Order Form and Service Description) may be changed by Sentrisense if
        and to the extent that this is necessary for good cause not foreseeable at the
        time of conclusion of this Agreement. Good cause shall be deemed to exist if
        <ul style={{ marginLeft: "1rem" }}>
          <li>
            the Service can no longer be provided in the contractually agreed form due to
            technical issues or new technical developments that Sentrisense needs to
            implement, or{" "}
          </li>{" "}
          <li>
            {" "}
            new or changed regulations or other legal requirements require a change in the
            Service.{" "}
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        12.3. Any other changes to the Contract, including the Sentrisense Tariffs, the
        Order Form, these Terms as well as the Service Description, shall be communicated
        by Sentrisense to the Customer in advance with reasonable prior notice in writing
        (text form).
      </Paragraph>
      <Paragraph>
        12.4. Where changes are made by Sentrisense during the term of the Contract and
        where such changes are not to the advantage of the Customer and the Customer is
        the end-user of the Service pursuant to German Telecommunications Act, Sentrisense
        shall notify Customer of such changes by giving Customer 1 (one) month prior
        written notice. Customer then has the right to terminate the affected Contract by
        written (text form) notice within a period indicated in the notice by Sentrisense.
        Sentrisense shall inform the Customer about this termination right in the change
        notice and the consequence that the change becomes valid and agreed, if the
        termination is not exercised.
      </Paragraph>
      <Paragraph>
        <Typography variant="h4" component="h4" mb="0.5rem">
          13. Miscellaneous Provisions
        </Typography>
      </Paragraph>
      <Paragraph>
        13.1. Assignment Notwithstanding any subcontracting by the Parties, neither Party
        may charge, assign or otherwise dispose of the agreement or any part thereof
        without prior written (text form) consent of the other Party. Such consent shall
        not be unreasonably delayed or withheld.
      </Paragraph>
      <Paragraph>
        13.2. Severability In the event that any provision of the Contract shall be
        invalid, illegal, or unenforceable, the validity of the remaining provisions of
        the Contract shall not be affected. The invalid, illegal or unenforceable
        provision shall be replaced by a provision that comes as close as possible to
        fulfilling the economic purpose of the invalid or unenforceable provision. The
        same shall apply in the event that the Parties subsequently discover that the
        Contract contains an omission.
      </Paragraph>
      <Paragraph>13.3. Confidentiality</Paragraph>
      <Box ml="1rem">
        <Paragraph>
          13.3.1. Each Party shall keep confidential and protect from unauthorized access
          all Confidential Information (as defined below) of the other Party. In
          particular, the Party receiving the Confidential Information shall only disclose
          the Confidential Information to its own employees and employees of its
          affiliated companies, to Subcontractors, Suppliers and advisors on a
          need-to-know basis for the proper fulfillment of the Contract, provided that
          these persons have agreed to the same confidentiality and non- disclosure
          obligations or are subject to statutory confidentiality obligations. The
          receiving Party shall treat Confidential Information with the same level of
          diligence and care that it applies to its own confidential information, and in
          any event with no less skill and care than that of a diligent businessperson. No
          Party shall make any property rights registration with respect to Confidential
          Information of the other Party.
        </Paragraph>
        <Paragraph>
          13.3.2. For the purposes of the Contract “Confidential Information“ shall mean
          a) status, content and result of the negotiations between the Parties relating
          to Contract, b) all information of any kind (irrespective of whether it is named
          or indicated as being confidential, a trade or business secret), which aa) was
          or is made available to the receiving Party by the disclosing Party or by third
          parties commissioned by the disclosing Party in the context of the
          implementation of the Contract - be it in oral, written, electronic or other
          format, or bb) comes to the knowledge of the receiving Party in the course of
          the implementation of the Contract, c) the intellectual property rights of each
          Party and d) all reports, analyses, technical operational documentation,
          compilations, memos, summaries, notes, excerpts or other materials in oral,
          written, electronic or other format that entail information within the meaning
          of lit. a) through c), or that are based on such information and which aa) were
          or are made available to the receiving Party by the disclosing Party or by third
          parties commissioned by the disclosing Party in the context of the
          implementation of the Contract, or bb) the receiving Party has obtained or will
          obtain, has created or will create, in the course of the implementation of the
          Contract.
        </Paragraph>
        <Paragraph>
          13.3.3. The foregoing provisions do not limit the rights of the Parties to the
          extent that (a) usage or disclosure of Information is required for the
          fulfillment of obligations or the exercise of rights under the Contract, or (b)
          usage or disclosure of Confidential Information is required by law or due to
          court or administrative order and the Party obliged thereunder has informed the
          other Party thereof in writing without undue delay, or (c) the affected Party,
          has given its prior written consent to a disclosure of its Information. Further,
          the foregoing provisions do not limit the disclosure of Confidential
          Information, if and to the extent that:
          <ul style={{ marginLeft: "1rem" }}>
            <li>
              the receiving Party can establish, that the Confidential Information (i) was
              known to it prior to the disclosure or knowledge without use of the
              Confidential Information, or (ii) has been developed by it independently
              from the Confidential Information,
            </li>{" "}
            <li>
              the Confidential Information, at the time of disclosure or knowledge by the
              receiving Party, is already or becomes publicly known or accessible without
              violation of the Contract or other confidentiality obligations of the
              receiving Party,
            </li>{" "}
            <li>
              the receiving Party has obtained the Confidential Information from a third
              party without violation of a confidentiality obligation towards the
              disclosing Party or of another confidentiality obligation, or
            </li>{" "}
            <li>
              the receiving Party discloses the Confidential Information to its auditors
              or legal advisers or other persons that are bound to confidentiality
              obligations due to their professional rules.{" "}
            </li>
          </ul>
        </Paragraph>
        <Paragraph>
          13.3.4. The foregoing confidentiality obligations apply during the Contract
          term, and for a period of three (3) years after the Contract&apos;s termination.
        </Paragraph>
      </Box>
      <Paragraph>13.4. Intellectual Property, Trademarks and Logos</Paragraph>
      <Box ml="1rem">
        <Paragraph>
          13.4.1. The Parties agree that Sentrisense exclusively owns and reserves all
          right, title and interest in and to the Sentrisense Services, Sentrisense&apos;s
          properties, software, other inventions whether or not patentable, copyrightable,
          protectable as trade secrets, or otherwise subject to intellectual property
          rights, and Sentrisense&apos;s confidential information. The Parties further
          agree that Customer exclusively owns and reserves all right, title and interest
          in and to the Customer data, Customer applications and Customer&apos;s
          confidential information.
        </Paragraph>

        <Paragraph>
          13.4.2. All title (intellectual property rights) in work products (for clarity
          including any configuration of the Services, the Sentrisense Dashboard and the
          other software used by Sentrisense), in particular all usage and exploitation
          rights under copyright law, rights in inventions and technical property rights
          shall vest in relation to Customer exclusively in Sentrisense, no matter whether
          and to what extent the work products are based on requirements or contributions
          of Customer.
        </Paragraph>
        <Paragraph>
          13.4.3. Except for the limited usage rights explicitly granted in the Contract
          all intellectual property rights as well as all other rights, interest and
          claims in relation to the Services, the Sentrisense Dashboard and the other
          software used by Sentrisense in this context shall remain with Sentrisense.
        </Paragraph>
        <Paragraph>
          13.4.4 The use of Sentrisense trademarks and logos by Customer and the use of
          Customer&apos;s trademarks and logos, including those of their respective
          affiliated companies, requires prior written (text form) consent of the affected
          Party by way of a separate agreement between the Parties.
        </Paragraph>
      </Box>
      <Paragraph>
        13.5. Place of Fulfillment, Choice of Law and Venue Place of performance and
        payment is Spain. Place of venue for all disputes arising from or out of the
        Contract shall be Spain. The Contract shall exclusively be governed by the laws of
        Spain. The application of uniform sales law, in particular the application of the
        Convention of the United Nations on Contracts for the International Sale of Goods
        (CISG) is expressly excluded.
      </Paragraph>
    </>
  )

  return actionable ? (
    <Box
      height={"100vh"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Container
        maxWidth="md"
        sx={{
          py: "2rem",
          maxHeight: "60vh",
          overflow: "auto",
          bgcolor: "background.paper",
          border: (theme) => `1px solid ${theme.palette.neutral[400]}`,
          borderRadius: SECTIONS_BORDER_RADIUS,
          boxShadow: (theme) => `0 0 4px 1px ${theme.palette.neutral[100]} inset`,
        }}
      >
        {content}
      </Container>
      <Stack pt={2} gap={2} alignItems={"center"}>
        <FormControlLabel
          label={t("terms_and_conditions.READ_AND_AGREED")}
          control={
            <Checkbox
              size="medium"
              checked={readAndAgreed}
              onChange={() => setReadAndAgreed(!readAndAgreed)}
            />
          }
        />
        <LoadingButton
          loading={isLoading}
          variant="contained"
          disabled={!readAndAgreed}
          onClick={handleAccept}
        >
          {t("terms_and_conditions.CONTINUE")}
        </LoadingButton>
      </Stack>
    </Box>
  ) : (
    <Container maxWidth="lg" sx={{ py: "3rem" }}>
      {content}
    </Container>
  )
}

export default TermsAndConditions
