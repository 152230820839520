import fp from "lodash/fp"

import type { IPlotReqConfig, IPlotView, RecordService } from "types/dashboard.types"
import type {
  DeviceTelemetryRes,
  EntityTelemetry,
  PowerlineTelemetryRes,
} from "types/telemetries.types"

import {
  formatBatteryStatusData,
  formatSignalStatusData,
} from "helpers/utils/telemetries"
import { formatTelemetryPlotData } from "helpers/utils/plots"

type GetFill = (view: IPlotView, config: IPlotReqConfig) => string[]
export const getFill: GetFill = (view, config) => {
  const minimalFill = ["_field"]
  const groupFill = fp.getOr(minimalFill, "group", config)
  const fill = fp.getOr(groupFill, "properties.fill", view)
  const legendColumns = fp.getOr([], "properties.legendColumns", view)
  const aggregates = fp.getOr([], "aggregate", config)
  const extra = [
    // conveniently add name to fill if needed by the legends to show (required by giraffe)
    ...(fp.includes("name", legendColumns) ? ["name"] : []),
    // if we have multiple aggregates, we need the result in the fill
    ...(aggregates.length > 1 ? ["result"] : []),
  ]
  return fp.uniq(fp.concat(fill, extra))
}

export const formatTelemetryData = (
  recordService: RecordService,
  data: DeviceTelemetryRes | PowerlineTelemetryRes | undefined,
  view: IPlotView,
) => {
  // needed for giraffe as we can't put code in the tooltip
  type AddName = (records: EntityTelemetry[]) => EntityTelemetry[]
  const addName: AddName = fp.map((record: EntityTelemetry) => ({
    ...record,
    name: recordService.getName(record),
  }))
  const result = addName(formatTelemetryPlotData(data))

  if (result && result.length > 0) {
    if (view.nameKey === "SIGNAL_LEVEL") {
      return formatSignalStatusData(result)
    }
    if (view.nameKey === "BATTERY_STATUS") {
      return formatBatteryStatusData(result)
    }
  }
  return result
}
