import { useMemo } from "react"
import { useOutletContext } from "react-router-dom"
import fp from "lodash/fp"

import type { Powerline } from "types/powerlines.types"

import useDevices from "helpers/hooks/useDevices"
import { getDevicesByPowerlineId } from "helpers/utils/powerlines"
import { BodyContainer, SectionContainer } from "widgets/styled/containers"
import DevicesTable from "widgets/device/DevicesTable"

const PowerlineDevices = () => {
  const { powerline, powerlineFetching } = useOutletContext<{
    powerline: Powerline
    powerlineFetching: boolean
  }>()
  const {
    devsWRelsByIdWStates,
    isFetching: devicesFetching,
    isLoading: devicesLoading,
  } = useDevices()

  const devices = useMemo(
    () =>
      devsWRelsByIdWStates
        ? getDevicesByPowerlineId(devsWRelsByIdWStates, powerline.id)
        : [],
    [devsWRelsByIdWStates, powerline],
  )

  const devicesFirstFetch = useMemo(
    () => devicesFetching && fp.isEmpty(devsWRelsByIdWStates),
    [devsWRelsByIdWStates, devicesFetching],
  )
  return (
    <BodyContainer disableGutters maxWidth="xl">
      <SectionContainer disableGutters maxWidth="xl">
        <DevicesTable
          devices={devices}
          isFetching={devicesFirstFetch}
          isLoading={devicesLoading || powerlineFetching}
        />
      </SectionContainer>
    </BodyContainer>
  )
}

export default PowerlineDevices
