import type { SxProps } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"

interface ISpinnerProps {
  styles?: SxProps
  size?: string
  disableGutters?: boolean
}

export default function Spinner({
  size,
  styles = {},
  disableGutters = false,
}: ISpinnerProps) {
  return (
    <Container
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...styles,
      }}
      disableGutters={disableGutters}
    >
      <CircularProgress size={size} />
    </Container>
  )
}
