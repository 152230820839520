import { useOutletContext } from "react-router-dom"

import type { Powerline } from "types/powerlines.types"

import { powerlinePlotDefs } from "helpers/config/plots"
import Dashboard from "widgets/powerlines/Dashboard"
import { BodyContainer } from "widgets/styled/containers"

const PowerlineInfo = () => {
  const { powerline, powerlineFetching } = useOutletContext<{
    powerline: Powerline
    powerlineFetching: boolean
  }>()

  const config = {
    type: "powerline",
    plotDefs: powerlinePlotDefs,
  }

  return (
    <BodyContainer disableGutters maxWidth="xl">
      <Dashboard
        powerlineId={powerline.id}
        powerlineFetching={powerlineFetching}
        config={config}
      />
    </BodyContainer>
  )
}

export default PowerlineInfo
